/* You can add global styles to this file, and also import other style files */

@import "@angular/material/prebuilt-themes/deeppurple-amber.css";

@font-face {
    font-family: "ZEISSFrutigerNextUI-Reg";
    src: url("./assets/fonts/FrutNextUI/ZEISSFrutigerNextUI-Reg.ttf") format("truetype");
}


// **************************************** style to hide text before material icons are rendered
.wf-loading, .wf-materialicons-n4-inactive {
    .material-icons {
        display: none;
    }
}

* {
    margin: 0;
    padding: 0;
    font-family: "ZEISSFrutigerNextUI-Reg", "Roboto", "Helvetica Neue", sans-serif !important;
    font-weight: 300;

    --frame-tile-height: 104px;
    --frame-tile-width: 122px;
    --frame-tile-width-spacings: 146px;
}

// * {
//     outline: 1px solid rgba(255, 0, 0, .5);
// }

/* DIGITAL MARKETING TOOL GLOBAL STYLE */
.dmt-container {
    margin-top: 64px;
}

.dmt-progress-wrapper {
    margin: 48px 0px;
}

.dmt-navigation {
    position: absolute;
    bottom: 24px;

    left: 48px;
    right: 48px;
}

.dmt-nav {
    font-size: 16px;
}

.dmt-clickable {
    cursor: pointer;
}

.dmt-left {
    text-align: left;
}

.dmt-right {
    text-align: right;
}

.dmt-close-icon {
    font-size: 32px;
    position: fixed;
    z-index: 10;
    height: 32px !important;
    width: 32px !important;

    top: 64px !important;
    right: 36px !important;
    cursor: pointer;
}

::-webkit-scrollbar {
    width: 8px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: lightgrey;
}

mat-icon {
    font-family: "Material Icons" !important;
}

html,
body {
    height: 100%;
    height: 100vh;
}

body {
    margin: 0;
    padding: 0;
}

#fixipad {
    position: fixed;
    top: 0 !important;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none;
    color: black;
}

/* Disable corner rounding */
.sharpen {
    border-radius: 0px !important;
}

.bold {
    font-weight: bold;
}

.t-right {
    text-align: right;
}

.centered {
    text-align: center;
}

.mat-sort-header-button {
    height: 48px;
    margin: 0px;
}

.mat-mdc-progress-spinner circle,
.mat-mdc-progress-spinner circle {
    stroke: #008bd0;
}

/*
* Own opacities
*/

.o2 {
    opacity: 0.2;
}

.o4 {
    opacity: 0.4;
}

.o6 {
    opacity: 0.6;
}

.o8 {
    opacity: 0.8;
}

.o10 {
    opacity: 1;
}

/* COLOR PALETTE from CZV -> https://scene.zeplin.io/project/5b34f9f107f05967a06c1dec */

/* Font-Color */
.text1 {
    color: #3e3e3e;
}
.text2 {
    color: #727272;
}
.text3 {
    color: #b4b4b4;
}
.text4 {
    color: #ffffff;
}

/* Background */
.bg01 {
    background-color: #008bd0;
}
.bg02 {
    background-color: #f8f8f8;
}
.bg03 {
    background-color: #efefef;
}
.bg04 {
    background-color: #d3d3d3;
}
.bg05 {
    background-color: #4b4e52;
}

.support-purplered {
    background: #a70240;
}
.support-purple-red-text {
    color: #a70240;
}

.support-inidgo {
    background: #141e8c;
}
.support-inidgo-text {
    color: #141e8c;
}

.support-azur {
    background: #055ed2;
}
.support-azur-text {
    color: #055ed2;
}

.support-skyblue {
    background: #6ab0e2;
}
.support-skyblue-text {
    color: #6ab0e2;
}

.support-green {
    background: #3c8a2e;
}
.support-green-text {
    color: #3c8a2e;
}

.support-neongreen {
    background: #e1ff32;
}
.support-neongreen-text {
    color: #e1ff32;
}

.support-lightgreen {
    background: #d9e906;
}
.support-lightgreen-text {
    color: #d9e906;
}

.support-orange {
    background: #ec6500;
}
.support-orange-text {
    color: #ec6500;
}

.support-brightorange {
    background: #ff1a00;
}
.support-brightorange-text {
    color: #ff1a00 !important;
}

.support-midnight {
    background: #00507b;
}
.support-midnight-text {
    color: #00507b;
}

.support-ocean {
    background: #00669c;
}
.support-ocean-text {
    color: #00669c;
}

/* default and hover color */
.mdc-text-field--filled, 
.mdc-text-field--filled:not(.mdc-text-field--disabled){
    background-color: transparent !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label,
.mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label--float-above {
    color: var(--mdc-theme-primary, #673ab7) !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label--float-above {
    color: var(--mdc-theme-primary, #673ab7) !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
    color: var(--mdc-theme-primary, #673ab7) !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
    border-bottom-color: var(--mdc-theme-primary, #673ab7) !important;
}
.mdc-line-ripple--active::after {
    color: var(--mdc-theme-primary, #673ab7) !important; 
}
.mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
    color: var(--cyan) !important
}

.date-picker .mdc-text-field--filled, 
.date-picker .mdc-text-field--filled:not(.mdc-text-field--disabled){
    background-color: var(--white-smoke) !important;
}


.campaign-accordion .mat-expansion-panel-body {
    padding-right: 0px;
    padding-left: 0px;
}

.campaign-accordion {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.campaign-accordion mat-expansion-panel {
    flex-shrink: 0;
    margin-left: 0 !important;
}

.icon {
    background-repeat: no-repeat !important;
    display: inline-block;
    vertical-align: top;
    fill: currentColor;
    height: 24px;
    width: 48px;
    justify-content: center;
}

.filter-icon {
    @extend .icon;
    height: 48px;
    background-size: 48px 48px !important;
    margin-left: calc((100% - 48px) / 2);
}

.filter {
    background: url("assets/filter/icon_filter.svg");
    width: 24px;
    margin-right: 12px;
}

.tablet-icon {
    background: url("assets/icons/tablet.svg");
    width: 24px;
    margin-right: 12px;
}

.restricted-height {
    min-height: 84px !important;
}

.last-campaign-element > .list-item {
    border: none !important;
}

.mat-mdc-tooltip.store-selection {
    font-size: initial;
    margin-left: 36px;
}

.ngx-slider {
    .ngx-slider-selection {
        background-color: rgba(0, 139, 209, 0.4) !important;
    }

    .ngx-slider-pointer {
        width: 16px !important;
        height: 16px !important;
        top: -6px !important;
        background-color: #008bd0 !important;
    }

    .ngx-slider-pointer.ngx-slider-active:after {
        background-color: transparent !important;
        outline: transparent;
    }
}

.ngx-slider .ngx-slider-pointer:after {
    width: 0px !important;
    height: 0px !important;
}

.ngx-slider-pointer {
    &:focus {
        outline: 0 !important;
    }
}

mat-tab-group div[mattablabelwrapper] {
    max-width: fit-content;
}

.adm-title {
    line-height: 36px;
    font-size: 30px;
}

.frame-container > .scrollable-content {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    height: fit-content !important;
}

[truncated] {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
}

.mat-calendar-body-in-range::before,.mat-calendar-body-today{
    background-color: rgba(0, 139, 208, 0.3) !important;
}
// Calendar-Range Input
.mat-calendar-body-selected,
.mat-calendar-body-cell:hover > .mat-calendar-body-cell-content.mat-focus-indicator {
    background-color: rgb(0, 139, 208) !important;
}

.mat-datepicker-toggle-default-icon {
    color: rgb(0, 139, 208) !important;
}

// Fixes mat-calendar purple-focued date referencing the picked start-date
.mat-calendar-body-cell:not(.mat-calendar-body-disabled) {
    &.mat-calendar-body-active {
        &:focus {
            .mat-calendar-body-cell-content:not(.mat-calendar-body-selected).mat-focus-indicator {
                background-color: rgba(0, 139, 208, 0.3) !important;
            }
        }
    }
}

.banner-image>mat-radio-group>mat-radio-button>div.mdc-form-field {
    align-items: flex-start;

    label {
        padding-top: 10px;
        pointer-events: none;

    }
}

.mat-mdc-radio-button.mat-accent{   
    --mat-radio-checked-ripple-color : var(--mdc-theme-primary, #673ab7) !important;
}
.mat-mdc-tab-group, .mat-mdc-tab-nav-bar{
    --mat-tab-header-active-focus-indicator-color : var(--mdc-theme-primary, #673ab7) !important;
    --mat-tab-header-active-hover-indicator-color : var(--mdc-theme-primary, #673ab7) !important;  
}

div>vcld-banner-input>div>div.banner-container>button,
div>vcld-banner-input>div>div.btns-container>button,
vcld-banner-input>div>div.btns-container>div>p>mat-icon {
    pointer-events: stroke;
}
.banner-tooltip, .rec-settings-tooltip{
    font-size: 14px !important;
    white-space: pre-line !important;
} 

vcld-adm-dmt-email-component-host label,
vcld-adm-invitation-mail-component-host label {
    font-size: 18px !important;
}

.mailing-components .banner {
    padding-left: 0px !important;
}
.overflowingTooltip {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
}

.rotation-3d-black {
    background: url("./assets/icons/3d_rotation_black.svg");
}


.viewer-dialog .mat-mdc-dialog-surface,
.rec-edit-dialog .mat-mdc-dialog-surface {
    overflow: hidden;
}

vcld-search input {
    max-height: 32px !important;
    margin-top: 0px !important;
    font-size: var(--ui-text-font-size) !important;
}

vcld-search .mat-mdc-form-field-subscript-wrapper {
    height: 0px;
}

vcld-search button[mat-icon-button] {
    height: 32px !important;
    width: 32px !important;
    padding: 4px !important;
}

.mat-mdc-form-field-infix,
.mat-mdc-form-field-underline {
    --mdc-outlined-text-field-caret-color: var(--mdc-theme-primary, #673ab7) !important;
    --mdc-filled-text-field-caret-color: var(--mdc-theme-primary, #673ab7) !important;
}

vcld-search .mat-mdc-form-field-underline {
    display: none;
}

vcld-search .mat-mdc-form-field-infix {
    padding: 0 !important;
    min-height: unset;
    display: flex;
    justify-content: center;
}

vcld-search [matformfieldlineripple], vcld-search .mat-mdc-form-field-focus-overlay {
    display: none;
}

 vcld-search .mdc-text-field--filled, vcld-search .mdc-text-field--focused {
    background: transparent !important;
 }

 vcld-search .mat-mdc-text-field-wrapper.mdc-text-field {
    padding-right: 0px;
 }

vcld-search {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

mat-form-field.hugeScreen-search > .mat-mdc-text-field-wrapper.mdc-text-field {
    height: 50px !important;
}


mat-form-field.language-settings>div {
    max-width: fit-content !important;
}

mat-list-item.mdc-list-item--with-leading-avatar {
    padding-right: 0px !important;
}

mat-list-item.mdc-list-item--with-leading-avatar > img {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.mdc-checkbox .mdc-checkbox__native-control:focus:checked~.mdc-checkbox__ripple{
    background-color: var(--mdc-theme-primary, #673ab7) !important;
}

.mat-mdc-checkbox.mat-primary {
    --mdc-checkbox-selected-hover-state-layer-color:  var(--mdc-theme-primary, #673ab7) !important;
}

.mat-mdc-button.mat-primary {
    --mat-text-button-state-layer-color:  var(--mdc-theme-primary, #673ab7) !important;
}

.mat-mdc-mini-fab[disabled],
.mat-mdc-mini-fab.mat-mdc-button-disabled {
    background-color: var(--mdc-theme-primary, #673ab7) !important;
}

html .mat-mdc-mini-fab.mat-primary {
    --mdc-fab-small-container-color: var(--mdc-theme-primary, #673ab7) !important;
}

vis-adm-session-page .mat-mdc-icon-button[disabled],
.mat-mdc-icon-button.mat-mdc-button-disabled {
    opacity: 1 !important;
}

.mat-mdc-icon-button.mat-primary {
    --mat-icon-button-state-layer-color: var(--mdc-theme-primary, #673ab7) !important;
}

@media only screen and (max-width: 1024px) {
    .small-page-fix {
        margin-left: 24px !important;
        margin-right: 24px !important;
        width: calc(100% - 48px) !important;
    }

    .dmt-close-icon {
        right: 24px !important;
    }

    mat-list-item.mdc-list-item--with-leading-avatar > img {
        width: 36px !important;
        height: 36px !important;
    }
}

@media only screen and (max-width: 1200px) {
    *{
        --frame-tile-height: 72px;
        --frame-tile-width: 70px;
        --frame-tile-width-spacings: 94px;
    }
    
    .adm-title {
        font-size: 22px;
    }
}

@media only screen and (min-width: 1201px) and (max-width: 1600px) {
    *{
        --frame-tile-height: 91px;
        --frame-tile-width: 100px;
        --frame-tile-width-spacings: 122px;
    }
}


.nose_ud_menu {
    position: fixed !important;

    left: 13.5%;
    top: 22%;

    min-width: 48px !important;

    overflow: hidden !important;
    background: transparent;
    box-shadow: none !important;   
    background-color: rgba(0,0,0,0) !important;
}

.nose_ud_menu_left {
    position: fixed !important;
    left: 48px;
    top: 28%;
    min-width: 48px !important;
    overflow: hidden !important;
    background: transparent;
    box-shadow: none !important;
    background-color: rgba(0,0,0,0) !important;
}

.nose_ud_menu_right {
    position: fixed !important;
    left: calc(50% + 48px);
    top: 28%;
    min-width: 48px !important;
    overflow: hidden !important;
    background: transparent;
    box-shadow: none !important;
    background-color: rgba(0,0,0,0) !important;
}

.nose_ud_menu-back {
    background: transparent;
}

.custom-slider .ngx-slider.vertical .ngx-slider-pointer {
    top: auto !important;
    left: -6px !important;
}

.custom-slider {
    width: 44px;
    height: calc(33vh + 16px);
}

.vertical-slider {
    height: 34px;
    width: 168px;
}

.rotation-slider {
    max-width: calc((128px * 1.5) + (2 * 48px) + 16px);
    box-shadow: rgba(0, 0, 0, .2) 0px 5px 5px;
}

.rotation-slider ngx-slider{
    min-width: 168px;
    margin-top: 16px;
}
