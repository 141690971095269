.mat-drawer-inner-container {
    background: #f8f8f8;
}

.mat-mdc-list-base .mat-mdc-list-item.mat-list-item-with-avatar,
.mat-list-base .mat-list-option.mat-list-item-with-avatar {
    height: 64px !important;
}

mat-sidenav-container,
mat-drawer-container {
    height: 100vh;
    height: 100%;
}

.cdk-overlay-pane {
    border-radius: 0px;
}

.mat-mdc-dialog-container {
    border-radius: 0px !important;
}

.cdk-overlay-pane.rec-edit-dialog > .mat-mdc-dialog-container {
    height: unset;
}

.rec-edit-dialog .mat-mdc-tab-body-wrapper {
    height: 100% !important;
}

.rec-edit-dialog {
    max-width: min(1600px, 98vw ) !important; 
    width: max(80vw, (var(--frame-tile-width-spacings) * 10)) !important;
    max-height: 1000px;
}