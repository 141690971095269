* {
    letter-spacing: normal !important;
    --cyan: #008BD0;
    --disabled-cyan: rgba(0, 139, 208, 0.3);
    --quick-silver: #A2A2A2;
    --white-smoke: #F7F7F7;
    --platinum: #E9E8E9;
    
    --mdc-checkbox-selected-focus-icon-color: var(--cyan) !important;
    --mdc-checkbox-selected-hover-icon-color: var(--cyan) !important;
    --mdc-checkbox-selected-icon-color: var(--cyan) !important;
    --mdc-checkbox-selected-pressed-icon-color: var(--cyan) !important;
    --mdc-checkbox-selected-checkmark-color: white !important;
    --mdc-checkbox-unselected-icon-color: #A2A2A2;
    --mdc-tab-indicator-active-indicator-color: var(--cyan) !important;
    --mdc-slider-active-track-color: black !important;
    --mdc-slider-inactive-track-color: black !important;
    --mdc-slider-focus-handle-color: transparent !important;
    --mdc-list-list-item-hover-leading-icon-color: black !important;
    --mdc-list-list-item-leading-icon-color: black !important;
    --mdc-slider-inactive-track-height: 2px;
    --mdc-slider-active-track-height: 2px;
    --slider-input-height: 19px;
    --slider-thumb-size: 14px;
    --mdc-theme-primary: var(--cyan);
    --mat-mdc-radio-checked-ripple-color: var(--cyan);
    --mdc-radio-selected-focus-icon-color: var(--cyan);
    --mdc-radio-selected-hover-icon-color: var(--cyan);
    --mdc-radio-selected-icon-color: var(--cyan);
    --mdc-radio-selected-pressed-icon-color: var(--cyan);
    --mdc-snackbar-container-color: white;
    --mdc-snackbar-supporting-text-color: black;
   }
  
  .mat-primary {
    --mdc-text-button-label-text-color: var(--cyan) !important;
    --mat-mdc-button-persistent-ripple-color: var(--cyan) !important;
    --mdc-filled-button-container-color: var(--cyan) !important;
    --mdc-icon-button-icon-color: var(--cyan) !important;
    --mdc-outlined-button-label-text-color: var(--cyan) !important;
    --mdc-outlined-button-outline-color: var(--cyan) !important;
    --mdc-filled-button-disabled-label-text-color: white !important;
    --mdc-filled-button-disabled-container-color: var(--cyan) !important;
    --mdc-fab-container-color: var(--cyan) !important;
  }

  .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected~.mdc-checkbox__ripple {
       background: var(--cyan); 
  } 


  .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
  .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
    color: var(--cyan);
  }

  .mdc-tab--active p {
    color: var(--cyan);
  }

  .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: var(--cyan);
  }

  .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
    color: var(--cyan);
  }

.white {
    background: #FFFFFF;
}

.white-text {
    color: #FFFFFF;
}

.white-smoke {
    background: #F7F7F7;
}

.white-smoke-text {
    color: #F7F7F7;
}

.platinum {
    background: #E9E8E9;
}

.platinum-text {
    color: #E9E8E9;
}

.quick-silver {
    background: #A2A2A2;
}

.quick-silver-text {
    color: #A2A2A2;
}

.cyan {
    background: #008BD0 !important;
}

.cyan-text {
    color: #008BD0;
}

.black {
    background: #000000;
}

.black-text {
    color: #000000;
}

.error {
    background: #FF647C;
}

.success {
    background: #00C48C;
}

.error-text {
    color: #FF647C;
}

.success-text {
    color: #00C48C;
}



.bold {
    font-weight: bold;
}
